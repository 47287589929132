import React from "react";
import PropTypes from "prop-types";
import { WatchForOutsideClick } from "components";
import { makeAssetURL, detectEnvironment } from "utility";
import ReactPlayer from "react-player";
import CONST from "constants.js";

export const Video360 = ({
  caption,
  currentPoi,
  media,
  onOutsideClick,
  styles,
}) => {
  const onLaunchYoutube = (e) => {
    if (e.currentTarget.dataset.environment === CONST.PLATFORM.IOS) {
      const start = new Date().valueOf();
      setTimeout(function () {
        const end = new Date().valueOf();
        if (end - start > 1000) return;
        window.location = "https://apps.apple.com/us/app/youtube/id544007664";
      }, 25);
    }
    window.location = `vnd.youtube://www.youtube.com/watch?v=${e.currentTarget.dataset.youtubeid}`;
  };

  const isAudio =
    (media && media.type.includes("audio")) ||
    (media && media.url && ["wav", "mp3"].includes(media.url.split(".").pop()));

  switch (detectEnvironment()) {
    case CONST.PLATFORM.IOS:
    case CONST.PLATFORM.ANDROID: {
      return (
        <div style={styles.outerContainer}>
          <WatchForOutsideClick onOutsideClick={onOutsideClick}>
            <div
              data-environment={detectEnvironment()}
              data-youtubeID={
                media.url.split("/")[media.url.split("/").length - 1]
              }
              style={styles.youtubeLauncher}
              onClick={onLaunchYoutube}
            >
              <div style={styles.previewImage}>
                <img
                  style={{ margin: "auto" }}
                  alt="click to launch"
                  src={makeAssetURL(media.preview)}
                />
              </div>
              <div style={styles.caption}>Click to Launch 360° in YouTube</div>
            </div>
          </WatchForOutsideClick>
        </div>
      );
    }
    default:
    case CONST.PLATFORM.STANDARD: {
      return (
        <div style={styles.outerContainer}>
          <WatchForOutsideClick onOutsideClick={onOutsideClick}>
            <div style={styles.innerContainer}>
              <div style={styles.header}>
                <div style={styles.backlink} onClick={onOutsideClick}>
                  [CLOSE]
                </div>
                <div style={styles.titleAndCaption}>
                  <div style={styles.title}>{currentPoi.name}</div>
                  {caption && caption.length > 0 && (
                    <div style={styles.caption}>{caption}</div>
                  )}
                </div>
              </div>
              <div style={styles.playerBox}>
                <ReactPlayer
                  {...(isAudio
                    ? {
                        playing: true,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        url: makeAssetURL(media.url),
                        controls: true,
                      }
                    : {
                        playing: true,
                        height: "50rem",
                        width: "100%",
                        url: media ? makeAssetURL(media.url) : "",
                        controls: true,
                      })}
                />
              </div>
            </div>
          </WatchForOutsideClick>
        </div>
      );
    }
  }
};
export default Video360;

Video360.defaultProps = {};
Video360.propTypes = {
  onOutsideClick: PropTypes.func,
};
