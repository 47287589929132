import React, { useState, useEffect } from "react";
import Markdown from "react-universal-markdown/dom";
import Content from "components/Content.js";
import * as mdContent from "assets/content";

function Mural() {
  const [contentVisible, setContentVisible] = useState(false);
  const [content, setContent] = useState("");
  const [markdown, setMarkdown] = useState("");
  const onAreaClick = (e) => {
    setContent(e.target.dataset.id);
    setContentVisible(true);
  };

  useEffect(() => {
    fetch(mdContent["muralAbout"])
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setMarkdown(text);
      });
  });

  return (
    <React.Fragment>
      {contentVisible && (
        <Content
          src={content}
          onOutsideClick={() => setContentVisible(false)}
        />
      )}
      <div
        style={{
          width: "100vw",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <div style={{ width: "1000px", margin: "auto" }}>
          <img
            alt="mural"
            src="https://assets.toasterlab.com/8edaca6c-98dc-4106-9d7e-a2b2119c9ea6/bdec6a4f-36fe-40c2-9a2c-82bc0a0096dc.jpeg"
            useMap="#image-map"
          />
          <map name="image-map">
            <area
              data-id={"jiyaPatel"}
              onClick={onAreaClick}
              alt="link"
              coords="700,508,810,539,822,593,845,633,822,663,762,677,693,667,696,577"
              shape="poly"
            />
            <area
              data-id={"milaZlatanovic"}
              onClick={onAreaClick}
              alt="link"
              coords="620,465,697,506,688,664,612,636,554,591"
              shape="poly"
            />
            <area
              data-id={"ashleyBeerdat"}
              onClick={onAreaClick}
              alt="link"
              coords="539,407,453,489,504,546,551,594,616,463"
              shape="poly"
            />
            <area
              data-id={"grantEvers"}
              onClick={onAreaClick}
              alt="link"
              coords="536,407,467,333,367,388,450,490,450,493"
              shape="poly"
            />
            <area
              data-id={"reyCastonguay"}
              onClick={onAreaClick}
              alt="link"
              coords="327,331,369,384,468,333,412,237,331,249,279,263"
              shape="poly"
            />
            <area
              data-id={"billyParrell"}
              onClick={onAreaClick}
              alt="link"
              coords="383,124,415,230,282,260,268,213,289,171,310,152"
              shape="poly"
            />
            <area
              data-id={"jimGraham"}
              onClick={onAreaClick}
              alt="link"
              coords="386,122,411,122,466,135,551,170,575,195,471,221,415,232"
              shape="poly"
            />
            <area
              data-id={"reitaT"}
              onClick={onAreaClick}
              alt="link"
              coords="419,239,467,326,585,268,575,198"
              shape="poly"
            />
            <area
              data-id={"alanaEvers"}
              onClick={onAreaClick}
              alt="link"
              coords="471,331,537,403,610,329,589,298,582,271"
              shape="poly"
            />
            <area
              data-id={"fahmeedaQureshi"}
              onClick={onAreaClick}
              alt="link"
              coords="613,333,651,347,666,354,619,458,544,403"
              shape="poly"
            />
            <area
              data-id={"shivaMoghaddasi"}
              onClick={onAreaClick}
              alt="link"
              coords="704,348,666,351,620,463,699,499"
              shape="poly"
            />
            <area
              data-id={"binaIsrani"}
              onClick={onAreaClick}
              alt="link"
              coords="707,353,699,500,811,534,794,496,752,386,727,348"
              shape="poly"
            />
            <area
              data-id={"yasmanMehrsa"}
              onClick={onAreaClick}
              alt="link"
              coords="610,225,641,232,683,270,711,306,689,324,645,317,613,281,605,250"
              shape="poly"
            />
          </map>
        </div>
        <div style={{ width: "62rem", margin: "auto" }}>
          <Markdown>{markdown}</Markdown>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Mural;
