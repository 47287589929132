import React, { useState } from "react";
import Markdown from "react-universal-markdown/dom";
import WatchForOutsideClick from "components/WatchForOutsideClick";
import * as content from "assets/content";
import colors from "colors";

const Content = ({ src, onOutsideClick }) => {
  const [markdown, setMarkdown] = useState("");
  fetch(content[src])
    .then((response) => {
      return response.text();
    })
    .then((text) => setMarkdown(text));

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <WatchForOutsideClick onOutsideClick={onOutsideClick}>
          <Markdown>{markdown}</Markdown>
        </WatchForOutsideClick>
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "left",
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  content: {
    borderRadius: "0.3rem",
    position: "relative",
    padding: "0 2rem 0 2rem",
    zIndex: 1,
    overflow: "scroll",
    height: "80vh",
    backgroundColor: colors.white,
    width: "75vw",
    boxShadow: "-1px 0px 7px #000000",
    maxWidth: "60rem"
  }
};
export default Content;
