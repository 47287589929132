import React from "react";
import Video360 from "./Video360.js";
import InlineVideo from "./InlineVideo.js";
import Website from "./Website.js";
import styles from "./videoStyles.js";

const PRESENTATION = {
  VIDEO360: 2,
  VIDEOINLINE: 3,
  WEBSITE: 4,
};

export const Presentation = ({ currentPoi, media, onOutsideClick }) => {
  if (!currentPoi) return null;
  let displayAs;
  switch (currentPoi.presentation_id) {
    default:
    case PRESENTATION.VIDEO360:
      displayAs = (
        <Video360
          {...currentPoi.presentation_config}
          currentPoi={currentPoi}
          media={media}
          onOutsideClick={onOutsideClick}
          styles={styles}
        />
      );
      break;
    case PRESENTATION.VIDEOINLINE:
      displayAs = (
        <InlineVideo
          {...currentPoi.presentation_config}
          currentPoi={currentPoi}
          media={media}
          onOutsideClick={onOutsideClick}
          styles={styles}
        />
      );
      break;
    case PRESENTATION.WEBSITE:
      displayAs = (
        <Website
          {...currentPoi.presentation_config}
          currentPoi={currentPoi}
          onOutsideClick={onOutsideClick}
          styles={styles}
        />
      );
      break;
  }

  return <div key={currentPoi.id}>{displayAs}</div>;
  /*
  const split = prefs.presentation_padding.value.split(" ");
  let padding = [];
  for (let x = 0; x < 4; x++) {
    padding[x] = split[x] ? parseFloat(split[x]) : 0;
  }

  const hPad = padding[1] + padding[3];
  const vPad = padding[0] + padding[2];
  const applyPadding = (styles) => {
    return {
      ...styles,
      outerContainer: {
        ...styles.outerContainer,
        padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
      },
      innerContainer: {
        ...styles.innerContainer,
        maxHeight: `calc(${styles.innerContainer.maxHeight} - ${vPad}px)`,
        maxWidth: `calc(${styles.innerContainer.maxWidth} - ${hPad}px)`,
      },
    };
  };
  const styles = {
    ...applyPadding(videoStyles),
  };

  let displayAs;
  switch (thisPresentation.id) {
    default:
    case 2: {
      displayAs = (
        <Video360
          {...currentPoi.presentation_config}
          currentPoi={currentPoi}
          availableMedia={availableMedia}
          media={media}
          onOutsideClick={onOutsideClick}
          styles={styles}
        />
      );
      break;
    }
    case 3: {
      displayAs = (
        <InlineVideo
          {...currentPoi.presentation_config}
          currentPoi={currentPoi}
          media={media}
          onOutsideClick={onOutsideClick}
          styles={styles}
        />
      );
      break;
    }
    case 4:
      displayAs = (
        <Website
          {...currentPoi.presentation_config}
          currentPoi={currentPoi}
          onOutsideClick={onOutsideClick}
          styles={styles}
        />
      );
      break;
  }

  return <div key={currentPoi.id}>{displayAs}</div>;*/
};
export default Presentation;

Presentation.defaultProps = {};
Presentation.propTypes = {};
