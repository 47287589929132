import React, { useEffect, useState } from "react";
import WatchForOutsideClick from "components/WatchForOutsideClick";
import data from "./config.json";
import styles from "./TagFilter.module.css";
const TagFilter = ({ mapSrc, onOutsideClick }) => {
  const [tags, setTags] = useState(data.content.map((item) => item.tag));
  const [years, setYears] = useState(data.year.map((item) => item.tag));

  useEffect(() => {
    let storedFilter_tags = localStorage.getItem("maptool_tagfilter_tags");
    if (storedFilter_tags) {
      storedFilter_tags = JSON.parse(storedFilter_tags);
      setTags(storedFilter_tags);
    }
    let storedFilter_years = localStorage.getItem("maptool_tagfilter_years");
    if (storedFilter_years) {
      storedFilter_years = JSON.parse(storedFilter_years);
      setYears(storedFilter_years);
    }
    if (storedFilter_tags || storedFilter_years) {
      const event = new CustomEvent("updateMapSrc", {
        detail: `${mapSrc}?tags=${
          storedFilter_years ? storedFilter_years : ""
        }${
          storedFilter_years?.toString().length > 0 &&
          storedFilter_tags?.toString().length > 0
            ? "+"
            : ""
        }${storedFilter_tags?.toString()}`
      });
      window.dispatchEvent(event);
    }
  }, [mapSrc]);

  const onSetBounds = (e) => {
    e.preventDefault();
    onReset();
    let bounds = e.currentTarget.dataset.bounds;
    const event = new CustomEvent("setMapBounds", {
      detail: `${mapSrc}?bounds=${bounds}`
    });
    window.dispatchEvent(event);
    onOutsideClick();
  };

  const onReset = () => {
    setYears(data.year.map((item) => item.tag));
    setTags(data.content.map((item) => item.tag));
    const event = new CustomEvent("updateMapSrc", {
      detail: mapSrc
    });
    localStorage.removeItem("maptool_tagfilter_tags");
    localStorage.removeItem("maptool_tagfilter_years");
    window.dispatchEvent(event);
    onOutsideClick();
  };

  const onUpdateTags = (e) => {
    e.preventDefault();
    let tag = e.currentTarget.dataset.tag;
    let newTags = [...tags];
    if (newTags.includes(tag)) {
      const index = newTags.indexOf(tag);
      if (index > -1) newTags.splice(index, 1);
    } else {
      newTags.push(tag);
    }
    setTags(newTags);
    localStorage.setItem("maptool_tagfilter_tags", JSON.stringify(newTags));
    const event = new CustomEvent("updateMapSrc", {
      detail: `${mapSrc}?tags=${years.toString()}${
        years.toString().length > 0 && newTags.toString().length > 0 ? "+" : ""
      }${newTags.toString()}`
    });
    window.dispatchEvent(event);
  };

  const onUpdateYears = (e) => {
    e.preventDefault();
    let year = e.currentTarget.dataset.year;
    let newYears = [...years];
    if (newYears.includes(year)) {
      const index = newYears.indexOf(year);
      if (index > -1) newYears.splice(index, 1);
    } else {
      newYears.push(year);
    }
    setYears(newYears);
    localStorage.setItem("maptool_tagfilter_years", JSON.stringify(newYears));
    const event = new CustomEvent("updateMapSrc", {
      detail: `${mapSrc}?tags=${newYears.toString()}${
        newYears.toString().length > 0 && tags.toString().length > 0 ? "+" : ""
      }${tags.toString()}`
    });
    window.dispatchEvent(event);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <WatchForOutsideClick onOutsideClick={onOutsideClick}>
          {data.year.length > 0 && <div className={styles.header}>Year</div>}
          <div className={styles.row}>
            {data.year.map((item, idx) => (
              <label
                key={`year_${idx}`}
                value={item.display}
                onClick={onUpdateYears}
                data-year={item.tag}
              >
                <div className={styles.row}>
                  <div
                    className={
                      years.includes(item.tag)
                        ? styles.checked
                        : styles.unchecked
                    }
                  />
                  <div className={styles.display}>{item.display}</div>
                </div>
              </label>
            ))}
          </div>
          {data.content.length > 0 && (
            <div className={styles.header}>Content</div>
          )}
          <div className={styles.col}>
            {data.content.map((item, idx) => (
              <label
                key={`content${idx}`}
                className="checkboxContainer"
                onClick={onUpdateTags}
                data-tag={item.tag}
              >
                <div className={styles.checkRow}>
                  <div
                    className={
                      tags.includes(item.tag)
                        ? styles.checked
                        : styles.unchecked
                    }
                  />
                  <div>
                    <img className={styles.icon} src={item.image} />
                  </div>
                  <div className={styles.display}>
                    <span className="checkmark"></span>
                    {item.display}
                  </div>
                </div>
              </label>
            ))}
          </div>
          <div className={styles.header}>Focus On</div>
          <div className={styles.row}>
            <div
              className={styles.button}
              onClick={onSetBounds}
              data-bounds={"rowentree_mills"}
            >
              <div>Rowntree Mills</div>
            </div>
            <div
              className={styles.button}
              onClick={onSetBounds}
              data-bounds={"panorama_park"}
            >
              <div>Panorama Park </div>
            </div>
            <div
              className={styles.buttonReset}
              onClick={onReset}
              data-tag={"reset"}
            >
              <div>Reset</div>
            </div>
          </div>
        </WatchForOutsideClick>
      </div>
    </div>
  );
};

export default TagFilter;
