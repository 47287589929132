import React from "react";
import PropTypes from "prop-types";
import { WatchForOutsideClick } from "components";
import { makeAssetURL } from "utility";
import ReactPlayer from "react-player";

export const InlineVideo = ({
  caption,
  currentPoi,
  media,
  onOutsideClick,
  styles,
}) => {
  const isAudio =
    (media && media.type.includes("audio")) ||
    (media && media.url && ["wav", "mp3"].includes(media.url.split(".").pop()));

  let playersettings = isAudio
    ? {
        playing: true,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        url: makeAssetURL(media.url),
        controls: true,
      }
    : {
        playing: true,
        width: "100%",
        url: media ? makeAssetURL(media.url) : "",
        controls: true,
      };

  return (
    <div style={styles.outerContainer}>
      <WatchForOutsideClick onOutsideClick={onOutsideClick}>
        <div style={styles.innerContainer}>
          <div style={styles.header}>
            <div style={styles.backlink} onClick={onOutsideClick}>
              [CLOSE]
            </div>
            <div style={styles.titleAndCaption}>
              <div style={styles.title}>{currentPoi.name}</div>
              {caption && caption.length > 0 && (
                <div style={styles.caption}>{caption}</div>
              )}
            </div>
          </div>
          <div style={styles.playerBox}>
            <ReactPlayer {...playersettings} />
          </div>
        </div>
      </WatchForOutsideClick>
    </div>
  );
};
export default InlineVideo;

InlineVideo.defaultProps = {};
InlineVideo.propTypes = {
  onOutsideClick: PropTypes.func,
};
