const constants = {
  DOES_NOT_EXIST: -1,

  APP_MODE: {
    API: 'API',
    DEMO: 'DEMO',
    EMBEDDED: 'EMBEDDED',
    STANDALONE: 'STANDALONE'
  },

  UI_MODE: {
    STANDARD: 'STANDARD',
    MAP_DRAWING: 'MAP_DRAWING',
    TOUR_PREVIEW: 'TOUR_PREVIEW'
  },

  ANON_HEADERS: {
    Accept: 'application/json, application/xml, text/plain, text/html, *.*',
    'Content-Type': 'application/json; charset=utf-8'
  },

  UPLOAD_TYPE: {
    USERPIC: 'USER',
    MEDIA: 'MEDIA',
    MEDIA_PREVIEW: 'MEDIA_PREVIEW'
  },

  PLATFORM: {
    DESKTOP: 0,
    ANDROID: 1,
    IOS: 2
  },

  UI: {
    SEARCH: 'search',
    MAP_DRAWING_TOOLS: 'mapDrawingTools',
    TIME_FILTER: 'timeFilter',
    TOUR_PREVIEW: 'tourPreview',
    MEDIA_LIBRARY: 'mediaLibrary',
    PREFERENCES: 'preferences',
    AUDIO_TOUR: 'audioTour',
    MEDIA_INSPECTOR: 'mediaInspector',
    POI_PRESENTATION: 'POI_PRESENTATION',
    GEOSEARCH: 'geosearch',
    USER_SELF_MANAGER: 'userSelfManager',
    USER_MANAGER: 'userManager',
    INFO: 'info',
    DIALOG: 'dialog'
  },

  // These need to match database ID (see api.const)
  // db: transition_type
  TRANSITION: {
    CUT: 1,
    DEVAMP: 3,
    FADE: 2,
    NONE: 0
  },
  // db: (hardcoded in user script)
  ROLE: {
    ANONYMOUS: 'webanon',
    USER: 'webuser',
    SUPERUSER: 'websuper'
  },
  // db: trigger_type
  TRIGGER: {
    LOCATION: 3,
    PROXIMITY: 2,
    DEFAULT: 1
  },
  // db: poi_representation
  REPRESENTATION: {
    PIN: 1,
    CIRCLE: 2,
    IMAGE_CUSTOM: 3,
    IMAGE_PREVIEW: 4
  }
};
export default constants;
