import colors from "colors";
const styles = {
  outerContainer: {
    zIndex: 100,
    position: "fixed",
    top: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: `#00000088`,
  },
  innerContainer: {
    zIndex: 5,
    borderRadius: "0.3rem",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "black",
    boxShadow: colors.shadow,
    width: "100vw",
    height: "100vh",
    maxWidth: "60rem",
    maxHeight: "50rem",
    overflow: "hidden",
  },
  titleAndCaption: {
    flexGrow: "1",
    padding: "0 0 0 0.5rem",
    background: "#d7d7d7",
    margin: "-1rem 0 0 0",
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "900",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "1rem 0 0 0",
  },
  youtubeLauncher: {
    backgroundColor: "#cccccc",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    bottom: "0",
    padding: "1rem",
    height: "20rem",
  },
  caption: {
    textAlign: "left",
    fontSize: "1rem",
    whiteSpace: "nowrap",
    overflow: "scroll",
    maxWidth: "75vw",
    margin: "-1.2rem 0 0 0",
  },
  previewImage: {
    cursor: "pointer",
    border: "1px solid black",
    height: "10rem",
    width: "10rem",
    borderRadius: "10rem",
    overflow: "hidden",
    display: "flex",
    backgroundColor: "white",
    marginBottom: "1rem",
  },
  backlink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px auto",
    minWidth: "4rem",
    maxWidth: "4rem",
    width: "4rem",
    fontWeight: "900",
    color: "rgb(34 34 34 / 61%)",
    fontSize: ".7rem",
    whiteSpace: "nowrap",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    background: "#d7d7d7",
    height: "4rem",
    minHeight: "4rem",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    positioned: "fixed",
    width: "60rem",
  },
  playerBox: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    width: "100%",
  },
};
export default styles;
