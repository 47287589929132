import React, { useState, useEffect } from "react";
import Content from "components/Content.js";
import PopoverMenu from "components/PopoverMenu.js";
import colors from "colors";
import Presentation from "components/Presentation";
import TagFilter from "components/TagFilter";
const mapSrc = "https://steps.toasterlab.com";

function Home() {
  const [showTagFilter, setShowTagFilter] = useState(false);
  const [content, setContent] = useState("landing");
  const [presentation, setPresentation] = useState("");
  const [mapSource, setMapSource] = useState(mapSrc);
  const onPoiSelect = (e) => {
    if (e.origin !== mapSrc) return;
    setPresentation(e.data);
  };
  const onMapSrcUpdate = (e) => {
    setMapSource(e.detail);
  };
  const onSetMapBounds = (e) => {
    setMapSource(e.detail);
  };
  useEffect(() => {
    window.addEventListener("message", onPoiSelect, false);
    window.addEventListener("updateMapSrc", onMapSrcUpdate, false);
    window.addEventListener("setMapBounds", onSetMapBounds, false);
    return () => {
      window.removeEventListener("message", onPoiSelect, false);
      window.removeEventListener("updateMapSrc", onMapSrcUpdate, false);
      window.removeEventListener("setMapBounds", onSetMapBounds, false);
    };
  }, []);

  const dropdownMenu = [
    {
      name: "Welcome",
      action: () => setContent("landing")
    },
    {
      name: "Rowntree Mills Park",
      action: () => setContent("rowntree")
    },
    {
      name: "Panorama Park",
      action: () => setContent("panorama")
    },
    {
      name: "About",
      action: () => setContent("about")
    },
    {
      name: "Projects",
      action: () => setContent("projects")
    },
    {
      name: "Funder Recognition",
      action: () => setContent("funderRecognition")
    },
    {
      name: "Tag Filter",
      action: () => setShowTagFilter(!showTagFilter)
    }
  ];
  return (
    <div className="Home">
      {presentation && (
        <Presentation
          {...presentation}
          onOutsideClick={() => setPresentation(null)}
        />
      )}
      <div
        style={{
          background: "#222222", //"-webkit-linear-gradient(270deg,#222222,transparent)",
          fontSize: "1.5rem",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          width: "100vw",
          height: "7rem",
          textAlign: "left",
          display: "flex",
          color: "white",
          alignItems: "center"
        }}
      >
        <div>
          <a href="https://stepsinitiative.com/">
            <img
              style={{ height: "120px", width: "auto", margin: "0 1rem" }}
              alt="STEPS logo"
              src="https://assets.toasterlab.com/8edaca6c-98dc-4106-9d7e-a2b2119c9ea6/52801daa-19d4-488c-b49b-86f50924e7b5.png"
            />
          </a>
        </div>
        <div style={styles.projectTitle}>
          From
          <br /> Weeds
          <br /> We Grow
        </div>
        <div>
          <PopoverMenu
            showArrow={false}
            resetToTop={true}
            forceLayout={11}
            items={dropdownMenu}
          >
            <div style={styles.burgerContainer}>
              <div style={styles.line} />
              <div style={styles.line} />
              <div style={styles.line} />
            </div>
          </PopoverMenu>
        </div>
      </div>
      {showTagFilter && (
        <TagFilter
          mapSrc={mapSrc}
          onOutsideClick={() => setShowTagFilter(false)}
        />
      )}
      {content && (
        <Content src={content} onOutsideClick={() => setContent(null)} />
      )}
      <iframe
        style={{ height: "100vh", marginTop: "5rem" }}
        title="map"
        width="100%"
        frameBorder="0"
        src={mapSource}
      />
    </div>
  );
}

const styles = {
  burgerContainer: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    width: "5rem",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.5rem",
    height: "6.7rem"
  },
  line: {
    backgroundColor: colors.white,
    width: "100%",
    height: ".5rem",
    margin: ".5rem 2rem .5rem 0",
    borderRadius: "0.3rem"
  },
  projectTitle: {
    position: "relative",
    flexGrow: 1,
    margin: "0 0 0 0",
    fontSize: "2rem",
    lineHeight: "2rem",
    fontWeight: 900,
    color: colors.hanBlue
  }
};

export default Home;
